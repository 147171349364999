import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import LogoPng from '@assets/images/logo.png';
import GoddessPng from '@assets/images/Goddess.png';
import { ReactComponent as XSvg } from '@assets/svg/x.svg';
import { ReactComponent as DiscordSvg } from '@assets/svg/discord.svg';
import classNames from 'classnames';
import tgPng from '@assets/images/tg.png';
import { AppConstants } from 'constant/constant';

export const HomeHeader: React.FC<{ className?: string }> = (props) => {
  const [active, _active] = useState(false);
  useEffect(() => {
    const watch = (ev: Event) => {
      const scrollTop = window.document.documentElement.scrollTop;
      if (scrollTop > 100) {
        _active(true);
        return;
      }
      _active(false);
    };
    window.document.addEventListener('scroll', watch);
    return () => {
      window.document.removeEventListener('scroll', watch);
    };
  }, []);
  return (
    <CptStyle className={classNames(props.className, { active })}>
      <div className="left">
        <img src={LogoPng} className="LogoPng" alt="" />
        <img src={GoddessPng} className="GoddessPng" alt="" />
      </div>
      <div className="right">
        <a className="launch" href={AppConstants.telegramApp} target="_blank" rel="noreferrer">
          <img src={tgPng} className="tgPng" alt="" />
          <span>Launch MiniAPP</span>
        </a>
        <a href={AppConstants.x} target="_blank" rel="noreferrer">
          <XSvg className="ani-cute" />
        </a>
        <a href={AppConstants.discord} target="_blank" rel="noreferrer">
          <DiscordSvg className="ani-cute" />
        </a>
      </div>
    </CptStyle>
  );
};
const CptStyle = styled.div`
  display: flex;
  height: 88px;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  transition: all ease 0.4s;
  &.active {
    background: rgba(20, 17, 22, 0.6);
    backdrop-filter: blur(3px);
    > .right {
      > .launch {
        background-color: #167fb2;
        color: #ffffff;
      }
    }
  }
  > .left {
    padding-top: 2.5vw;
    > .LogoPng {
      width: 7.5vw;
      position: relative;
    }
    > .GoddessPng {
      width: 11.8vw;
      position: relative;
      top: -0.5vw;
      left: -4.7vw;
    }
  }
  > .right {
    display: flex;
    padding-right: 26px;
    gap: 16px;
    align-items: center;
    justify-content: center;
    > .launch {
      transition: all ease 0.4s;
      width: 203px;
      height: 50px;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-weight: bold;
      font-size: 16px;
      color: #ffecb2;
      padding-left: 36px;
      position: relative;
      box-sizing: border-box;
      > img {
        width: 50px;
        position: absolute;
        left: 0;
        top: 1px;
      }
    }
    > svg {
      cursor: pointer;
    }
  }

  @media screen and (max-width: 750px) {
    height: 151px;
    background: linear-gradient(180deg, #150e11aa, #150e1100);
    align-items: flex-start;
    &.active {
      background: linear-gradient(180deg, #150e11aa, #150e1100);
      backdrop-filter: none;
      > .right {
        > .launch {
          background-color: transparent;
        }
      }
    }
    > .left {
      padding-top: 10px;
      > .LogoPng {
        width: 80px;
        position: relative;
      }
      > .GoddessPng {
        width: 124px;
        position: relative;
        top: -5px;
        left: -54px;
      }
    }
    > .right {
      padding-top: 10px;
      > .launch {
        width: 48px;
        height: 48px;
        padding-left: 0;
        border-radius: 0;
        background-color: transparent;
        > span {
          display: none;
        }
        > img {
          width: 48px;
        }
      }
      > a > svg {
        width: 38px;
        height: 38px;
      }
    }
  }
`;
