// import { HomeGame } from '@components/home/game';
import { HomeHeader } from '@components/home/header';
import { HomeMain } from '@components/home/main';
import { HomeFooter } from '@components/home/footer';

// import { HomeTeam } from '@components/home/team';
// import { HomeWhat } from '@components/home/what';
import React from 'react';
import styled from 'styled-components';
// import BgJpg from '@assets/images/bg.jpg';

export const AppPage: React.FC = () => {
  return (
    <Div>
      <HomeHeader />
      {/* <div className="BgJpg" /> */}
      <HomeMain />
      {/* <HomeGame />
      <HomeWhat />
      <HomeTeam /> */}
      <HomeFooter />
    </Div>
  );
};

const Div = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 1;
  overflow: hidden;
  > .BgJpg {
    top: 56.25vw;
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    bottom: 0;
    z-index: -1;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media screen and (max-width: 750px) {
    > .BgJpg {
    }
  }
`;
