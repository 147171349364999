import React from 'react';
import styled from 'styled-components';

export const HomeFooter: React.FC<{ className?: string }> = (props) => {
  const currentYear = new Date().getFullYear();
  
  return (
    <CptStyle className={props.className}>
      <div className="copyright">
        Copyright © {currentYear} Goddess AI. Powered by <a href='https://litepaper.aidea.game/' target="_blank" rel="noopener noreferrer">AIdea Game</a>. 
      </div>
    </CptStyle>
  );
};

const CptStyle = styled.div`
  width: 100vw;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  background: transparent;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;

  .copyright {
    font-family: "Amiri-Bold", serif;
    font-size: 18px;
    opacity: 0.8;

    a {
      color: #fff;
      text-decoration: none;
      &:hover {
        color: #fff;
        text-decoration: underline;
      }
    }
  }
`;
